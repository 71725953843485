<template>
	<div class="page">
		<el-form class="form" label-width="100px">
			

			<div class="big_tit">装货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input style="width:40%" v-model="form.case_addr_full_text" placeholder="装货地省市区" disabled>
					</el-input>
					<el-input style="width:60%"  v-model="form.case_addr" placeholder="装货地详细地址(地图选点)" >
						<el-button slot="append" @click="case_addr_map_open">地图</el-button>
					</el-input>
					<!-- <el-input v-model="form.case_addr_full_text" placeholder="装货地详细地址(地图选点)" >
						<el-button slot="append" @click="case_addr_map_open">地图</el-button>
					</el-input> -->
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.case_link_man" placeholder="装货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_link_tel"  placeholder="装货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input style="width:40%" v-model="form.aim_addr_full_text" placeholder="卸货地省市区" disabled></el-input>
					<el-input style="width:60%" v-model="form.aim_addr" placeholder="卸货地详细地址(地图选点)" >
						<el-button slot="append" @click="aim_addr_map_open">地图</el-button>
					</el-input>
					<!-- <el-input v-model="form.aim_addr_full_text" placeholder="卸货地详细地址(地图选点)" >
						<el-button slot="append" @click="aim_addr_map_open">地图</el-button>
					</el-input> -->
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.aim_link_man" placeholder="卸货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_link_tel"  placeholder="卸货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">货物信息/结算相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="线路名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.bl_name" placeholder="线路名称, 非必填" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物名称" style="width:288px">
					<el-autocomplete 
						class="el_inner_width"
						v-model="form.cargo_name"
						placeholder="请输入货物名称"
						:fetch-suggestions="used_cargo_names_init"
						@change="cargo_name_change"
						@select="cargo_name_change"
						clearable
					></el-autocomplete>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物类型" style="width:280px">
					<el-select class="el_inner_width" v-model="form.cargo_type" clearable>
						<el-option v-for="(item,index) in cargo_type" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="结算方式" style="width:388px">
					<el-select class="el_inner_width" v-model="form.settlement_type" @change="settlement_type_change" clearable>
						<el-option label="按吨结算" value="1"></el-option>
						<el-option label="按方结算" value="2"></el-option>
						<el-option label="按趟结算" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运费" style="width:588px">
					<el-input class="el_inner_width" v-model="form.freight_total"  placeholder="单位(元), 非必填" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item"  style="width:388px"  v-if="form.settlement_type!=1">
				</el-form-item>
					<!--  -->
				<el-form-item class="el_form_item" label="货损吨数" style="width:388px" v-if="form.settlement_type==1">
					<el-input class="el_inner_width" v-model="form.damage_weight" type="number">
						<div style="margin-right: 4px;" slot="suffix">吨</div>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="单价" style="width:288px">
					<el-input class="el_inner_width" v-model="form.price"  placeholder="单价" >
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="单位" style="width:280px">
					<el-select class="el_inner_width" v-model="form.price_unit" clearable>
						<el-option v-for="(item,index) in price_units" :key="index" :label="item" :value="item" ></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物单价" style="width:388px" v-if="form.settlement_type==1">
					<el-input class="el_inner_width" v-model="form.cargo_price" type="number">
						<div style="margin-right: 4px;" slot="suffix">元</div>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="是否已购保险" style="width:388px">
					<el-select class="el_inner_width" v-model="form.is_insure" clearable>
						<el-option label="需要购买" value="2"></el-option>
						<el-option label="无需购买" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:588px">
					<el-input class="el_inner_width" v-model="form.distance" type="number" placeholder="单位(公里), 非必填" clearable>
						<el-button slot="append">公里</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物价值" style="width:388px" v-if="form.is_insure==1">
					<el-input class="el_inner_width" v-model="form.goods_total"  placeholder="请输入货物价值" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner" v-if="class_hide">
				<el-form-item class="el_form_item" label="预付金额" style="width:388px">
					<el-input class="el_inner_width" v-model="form.yufu_total"  placeholder="请输入预付金额" clearable>
						<el-button slot="append">元</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit">线路设置</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="必填吨位" style="width:388px">
					<el-select  class="el_inner_width" v-model="form.is_need_weight" :disabled="form.is_need_weight_lock" placeholder="是否必填吨数">
						<el-option label="必填吨数" value="1"></el-option>
						<el-option label="不必填吨数" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="必填体积" style="width:288px">
					<el-select class="el_inner_width" v-model="form.is_need_volume" :disabled="form.is_need_volume_lock" placeholder="是否必填体积">
						<el-option label="必填体积" value="1"></el-option>
						<el-option label="不必填体积" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="接单自动发车" style="width:280px">
					<el-select class="el_inner_width" v-model="form.is_auto_tord_start">
						<el-option label="自动发车" value="1"></el-option>
						<el-option label="不自动发车" value="2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item class="el_form_item" label="项目列表" style="width:285px;margin-top: 20px;margin-bottom: 20px;" v-if="company_item_list.length > 0">
					<el-select   class="el_inner_width" v-model="form.item_id">
						<el-option label="无" value="0"></el-option>
						<el-option v-for="(item,index) in company_item_list" :key="index" :label="item.item_name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="big_tit">收货方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收货方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consignee" placeholder="收货方名称(公司)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consigneeid" placeholder="统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>
			<div class="big_tit">销售方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="销售方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_name" placeholder="收货方名称(公司)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_code" placeholder="销售方统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>	
		</el-form>
		<div class="bottom_btns">
			<el-button type="primary" @click="sub(1)">提交</el-button>
			<el-button type="primary" @click="sub(2)">未支付运单不同步单价</el-button>
		</div>


		<!-- 发货地选择 -->
		<el-dialog title="发货地选择" top="1vh" width="90%" :visible.sync="case_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="case_addr_map_choose.is_show" 
				:old_data="case_addr_map_choose.old_data" 
				@choosed="case_addr_map_choosed"
			></mapChoose>
		</el-dialog>

		<!-- 卸货地选择 -->
		<el-dialog title="卸货地选择" top="1vh" width="90%" :visible.sync="aim_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="aim_addr_map_choose.is_show" 
				:old_data="aim_addr_map_choose.old_data" 
				@choosed="aim_addr_map_choosed"
			></mapChoose>
		</el-dialog>
	</div>
</template>
<script>
	import {regionDataPlus,CodeToText,TextToCode} from 'element-china-area-data'
	import {mapState} from 'vuex'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import mapChoose from '@/components/map/amap/choose.vue'
	export default {
		components:{
			mapChoose
		},
		data() {
			return {
				//可显示预付金额得手机号
				is_show_tel:[
				'13783981152',
				'18855370372',
				'13783981152',
				'18056918989',
				'13453497870'
				],

				class_hide:false,//是否显示预付金额
				//省市县基础数据
				cities:regionDataPlus,

				//使用过的货物名称
				used_cargo_names:[],

				//需要编辑的线路id
				bl_id:'',

				//表单
				form: {

					//装货省市区
					case_prov:{
						name:'',
						code:'',
					},
					case_city:{
						name:'',
						code:'',
					},
					case_county:{
						name:'',
						code:'',
					},
					item_id:'',//项目id
					//装货地联系人
					case_link_man:'',

					//装货地联系电话
					case_link_tel:'',

					//装货省详细地址
					case_addr:"",
					case_addr_full_text:'',

					//发货地经纬度
					case_lat:'',
					case_lng:'',

					//卸货省市区
					aim_prov:{
						name:'',
						code:'',
					},
					aim_city:{
						name:'',
						code:'',
					},
					aim_county:{
						name:'',
						code:'',
					},

					//装货地联系人
					aim_link_man:'',

					//装货地联系电话
					aim_link_tel:'',
					
					//卸货详细地址
					aim_addr:"",
					aim_addr_full_text:"",

					//卸货地经纬度
					aim_lat:'',
					aim_lng:'',

					//运输距离
					distance:'',

					//线路名称
					bl_name:'',

					//货物名称
					cargo_name:'',

					//货物类型
					cargo_type:'',

					//结算方式(1:按吨数,2:按方数,3:按趟)
					settlement_type:'',

					//运费
					freight_total:'',

					//是否必填货物重量(1:是,2:否)
					is_need_weight:'',
					is_need_weight_lock:false,

					//是否必填货物体积(1:是,2:否)
					is_need_volume:'',
					is_need_volume_lock:false,

					//针对线路接单时是否自动发车(1:自动发车,2:不自动发车)
					is_auto_tord_start:'1',

					//是否回程(1:需要,2:不需要)
					is_need_return:'',
					
					//是否已购买保险(1:已购买,2:未购买)
					is_insure:"",
					

					goods_total:0,//货物价值
					price_unit:'吨',

					price:'',//单价
					//备注
					mark:'',

					damage_weight:'',//货损吨数

					cargo_price:'',//货物单价

					consignee:'',//收货方

					consigneeid:'',//收货方信用代码

					seller_name:'',//销售方

					seller_code:'',//销售方社会信用代码

					yufu_total:'',//预付金额
				},
				LngLat:{
					lng:'',
					lat:'',
				},
				//货物类型
				cargo_type:[],
				
				price_units:["吨","方","辆"],

				//发货地地图选择
				case_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
						prov_name:'',
						city_name:'',
						county_name:'',
					}
				},

				//卸货地地图选择
				aim_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
						prov_name:'',
						city_name:'',
						county_name:'',
					}
				},
				company_item_list:'',//项目列表下拉框
			}
		},
		computed:{
			...mapState(['user_info'])
		},
		created() {
			if(this.is_show_tel.indexOf(this.user_info.tel)!=-1){
				this.class_hide =true
			}
			//取出id
			this.bl_id=this.$route.query.id

			//读取线路数据
			this.get_bl_info()

			//使用过的货物名称初始化
			this.set_used_cargo_name();
			
			//获取参数
			this.get_creat_para();

			//查询项目列表
			this.get_company_item_list()
			//高德地图插件初始化
			this.$amap.load({
				plugins:[
					'AMap.Driving',
				]
			}).then((AMap)=>{

				//初始化
				this.amapDriving = new AMap.Driving({
					policy:AMap.DrivingPolicy.LEAST_TIME,//路线规划策略
				})
			})
		},
		methods: {
			//发货地地址选择相关
			case_addr_map_open(){
				this.case_addr_map_choose.is_show=true;
				this.case_addr_map_choose.old_data.prov_code=this.form.case_prov.code
				this.case_addr_map_choose.old_data.city_code=this.form.case_city.code
				this.case_addr_map_choose.old_data.county_code=this.form.case_county.code
				this.case_addr_map_choose.old_data.lat=this.form.case_lat
				this.case_addr_map_choose.old_data.lng=this.form.case_lng
				this.case_addr_map_choose.old_data.addr=this.form.case_addr?this.form.case_addr:''
				this.case_addr_map_choose.old_data.prov_name=this.form.case_prov.name
				this.case_addr_map_choose.old_data.county_name=this.form.case_county.name
				this.case_addr_map_choose.old_data.city_name=this.form.case_city.name
			},
			case_addr_map_choosed(rel){
				this.case_addr_map_choose.is_show=false;
				this.form.case_prov.name=rel.prov_name
				this.form.case_prov.code=rel.prov_code
				this.form.case_city.name=rel.city_name
				this.form.case_city.code=rel.city_code
				this.form.case_county.name=rel.county_name
				this.form.case_county.code=rel.county_code
				this.form.case_addr=rel.addr_info
				this.form.case_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name}`
				this.form.case_lng=rel.lng
				this.form.case_lat=rel.lat
				this.distance_calc();
			},

			//卸货地地址选择相关
			aim_addr_map_open(){
				this.aim_addr_map_choose.is_show=true;
				this.aim_addr_map_choose.old_data.prov_code=this.form.aim_prov.code
			
				this.aim_addr_map_choose.old_data.city_code=this.form.aim_city.code
				this.aim_addr_map_choose.old_data.county_code=this.form.aim_county.code
                this.aim_addr_map_choose.old_data.lat=this.form.aim_lat
				this.aim_addr_map_choose.old_data.lng=this.form.aim_lng
				this.aim_addr_map_choose.old_data.addr=this.form.aim_addr?this.form.aim_addr:''

				this.aim_addr_map_choose.old_data.prov_name=this.form.aim_prov.name
				this.aim_addr_map_choose.old_data.county_name=this.form.aim_county.name
				this.aim_addr_map_choose.old_data.city_name=this.form.aim_city.name
			},
			aim_addr_map_choosed(rel){
				this.aim_addr_map_choose.is_show=false;
				this.form.aim_prov.name=rel.prov_name
				this.form.aim_prov.code=rel.prov_code
				this.form.aim_city.name=rel.city_name
				this.form.aim_city.code=rel.city_code
				this.form.aim_county.name=rel.county_name
				this.form.aim_county.code=rel.county_code
				this.form.aim_addr=rel.addr_info
				this.form.aim_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name}`
				this.form.aim_lng=rel.lng
				this.form.aim_lat=rel.lat
				this.distance_calc();
			},

			//计算运输距离
			distance_calc(){

				//发到货地经纬度齐备时计算导航距离
				if(!(this.form.case_lng&&this.form.case_lat&&this.form.aim_lng&&this.form.aim_lat)){
					return;
				}

				//计算导航距离
				this.amapDriving.search(
					new AMap.LngLat(this.form.case_lng,this.form.case_lat),//起点
					new AMap.LngLat(this.form.aim_lng,this.form.aim_lat),//终点
					(status,result)=>{
						if(status=='complete'&&result.info=='OK'){
							this.form.distance=(result.routes[0].distance/1000).toFixed(3)
						}
					}
				)
			},

			//提交
			sub(num){
				var bl_act_price=''
				if(num ==2){
					bl_act_price = 2
				}
				//装货地省市县
				if(!(this.form.case_prov.name&&this.form.case_city.name&&this.form.case_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地数据为空,请选择装货地"
					});
					return;
				}

				//装货地详情
				if(!this.form.case_addr.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"装货地详情数据为空,请输入装货地地址"
					});
					return;
				}

				//装货地联系电话
				if(!(this.$my.check.is_tel(this.form.case_link_tel)&&this.form.case_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地联系电话格式有误"
					});
					return;
				}

				//到货地省市县
				if(!(this.form.aim_prov.name&&this.form.aim_city.name&&this.form.aim_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"到货地数据为空,请选择到货地"
					});
					return;
				}

				//到货地详情
				if(!this.form.aim_addr.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"到货地详情数据为空,请输入到货地地址"
					});
					return;
				}
				
				//卸货地联系电话
				if(!(this.$my.check.is_tel(this.form.aim_link_tel)&&this.form.aim_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"卸货地联系电话格式有误"
					});
					return;
				}

				// //运输距离
				// if(!(this.$my.check.is_num(this.form.distance)&&this.form.distance>0)){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:"运输距离不能小等于0,请输入"
				// 	});
				// 	return;
				// }

				//货物名称
				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"货物名称为空,请输入货物名称"
					});
					return;
				}

				//结算方式
				if(this.form.settlement_type=='1'&&this.form.is_need_weight!='1'){//按吨数结算时吨数没有必填
					this.$my.other.msg({
						type:'warning',
						str:"按吨数结算时吨数没有必填"
					});
					return;
				}
				if(this.form.settlement_type=='2'&&this.form.is_need_volume!='1'){//按体积结算时体积没有必填
					this.$my.other.msg({
						type:'warning',
						str:"按体积结算时体积没有必填"
					});
					return;
				}
				// if(this.form.settlement_type=='3'&&this.form.distance==0){//按趟结算时必须填运输距离
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:"按趟结算时必须填运输距离"
				// 	});
				// 	return;
				// }

				//运费
				if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total<0){
					this.$my.other.msg({
						type:'warning',
						str:"运费不能小等于0,请输入"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_edit_shipper',
						bl_id:this.bl_id,
						bl_act_price:bl_act_price,
						case_prov:this.form.case_prov.name,
						case_city:this.form.case_city.name,
						case_county:this.form.case_county.name,
						case_link_man:this.form.case_link_man,
						case_link_tel:this.form.case_link_tel,
						case_lat:this.form.case_lat,
						case_lng:this.form.case_lng,
						case_other:{
							addr_info:this.form.case_addr.trim(),
							prov_code:this.form.case_prov.code,
							city_code:this.form.case_city.code,
							county_code:this.form.case_county.code,
						},
						damage_weight:this.form.damage_weight,
						cargo_price:this.form.cargo_price,
						consigneeid:this.form.consigneeid,
						consignee:this.form.consignee,
						seller_code:this.form.seller_code,
						seller_name:this.form.seller_name,
						price:this.form.price,
						price_unit:this.form.price_unit,
						aim_prov:this.form.aim_prov.name,
						aim_city:this.form.aim_city.name,
						aim_county:this.form.aim_county.name,
						aim_link_man:this.form.aim_link_man,
						aim_link_tel:this.form.aim_link_tel,
						aim_lat:this.form.aim_lat,
						aim_lng:this.form.aim_lng,
						aim_other:{
							addr_info:this.form.aim_addr.trim(),
							prov_code:this.form.aim_prov.code,
							city_code:this.form.aim_city.code,
							county_code:this.form.aim_county.code,
						},
						item_id:this.form.item_id,
						distance:this.form.distance,
						bl_name:this.form.bl_name,
						cargo_name:this.form.cargo_name,
						cargo_type:this.form.cargo_type,
						settlement_type:this.form.settlement_type,
						is_need_weight:this.form.is_need_weight,
						is_need_volume:this.form.is_need_volume,
						is_auto_tord_start:this.form.is_auto_tord_start,
						is_need_return:this.form.is_need_return,
						is_insure:this.form.is_insure,
						goods_total:this.form.goods_total,
						freight_total:this.form.freight_total,
						mark:this.form.mark,
						yufu_total:this.form.yufu_total
					},
					callback:(data)=>{

						//前往列表页
						this.$router.push({
							path:'/pages/business_line/bl_list'
						});
					}
				});
			},

			//结算方式变化
			settlement_type_change(){

				switch(this.form.settlement_type){
					
					case '1'://按吨数结算
						this.form.is_need_weight='1';//吨数必填
						this.form.is_need_weight_lock=true;//锁定吨数必填选择器
						this.form.is_need_volume_lock=false;//解锁方数必填选择器
						break;
					
					case '2'://按体积结算
						this.form.is_need_volume='1';//体积必填
						this.form.is_need_weight_lock=false;//解锁吨数必填选择器
						this.form.is_need_volume_lock=true;//锁定方数必填选择器
						break;
					
					case '3'://按趟结算
						this.form.is_need_weight_lock=false;//解锁吨数必填选择器
						this.form.is_need_volume_lock=false;//解锁方数必填选择器
						break;
				}
			},

			//读取线路详情
			get_bl_info(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_shipper',
						id:this.bl_id
					},
					callback:(data)=>{
						//取出线路信息
						let bl_info=data.list[0]

						//装货地其他数据
						bl_info.case_other_obj=JSON.parse(bl_info.case_other);

						//装货地省市县
						this.form.case_prov={
							name:bl_info.case_prov,
							code:bl_info.case_other_obj.prov_code,
						}
						this.form.case_city={
							name:bl_info.case_city,
							code:bl_info.case_other_obj.city_code,
						}
						this.form.case_county={
							name:bl_info.case_county,
							code:bl_info.case_other_obj.county_code,
						}

						//装货地详细地址
						this.form.case_addr=bl_info.case_other_obj.addr_info
						this.form.case_addr_full_text=`${bl_info.case_prov} / ${bl_info.case_city} / ${bl_info.case_county} `

						//装货地经纬度
						this.form.case_lng=bl_info.case_lng
						this.form.case_lat=bl_info.case_lat

						this.form.item_id = bl_info.item_id
						//装货联系人
						this.form.case_link_man=bl_info.case_link_man
						this.form.case_link_tel=bl_info.case_link_tel

						//收货方信息
						this.form.consignee = bl_info.consignee
						this.form.consigneeid = bl_info.consigneeid
						
						//销售方信息
						this.form.seller_code=bl_info.seller_code,
						this.form.seller_name=bl_info.seller_name
						//卸货地其他数据
						bl_info.aim_other_obj=JSON.parse(bl_info.aim_other);

						this.form.damage_weight = bl_info.damage_weight

						this.form.cargo_price = bl_info.cargo_price
						//卸货地省市县
						this.form.aim_prov={
							name:bl_info.aim_prov,
							code:bl_info.aim_other_obj.prov_code,
						}
						this.form.aim_city={
							name:bl_info.aim_city,
							code:bl_info.aim_other_obj.city_code,
						}
						this.form.aim_county={
							name:bl_info.aim_county,
							code:bl_info.aim_other_obj.county_code,
						}

						//卸货地详细地址
						this.form.aim_addr=bl_info.aim_other_obj.addr_info
						this.form.aim_addr_full_text=`${bl_info.aim_prov} / ${bl_info.aim_city} / ${bl_info.aim_county}`

						//卸货地经纬度
						this.form.aim_lng=bl_info.aim_lng
						this.form.aim_lat=bl_info.aim_lat

						//卸货联系人
						this.form.aim_link_man=bl_info.aim_link_man
						this.form.aim_link_tel=bl_info.aim_link_tel

						//运输距离
						this.form.distance=bl_info.distance

						//线路名称
						this.form.bl_name=bl_info.bl_name

						//货物名称
						this.form.cargo_name=bl_info.cargo_name

						//货物类型
						this.form.cargo_type=bl_info.cargo_type

						//结算方式(1:按吨数,2:按方数,3:按趟)
						this.form.settlement_type=bl_info.settlement_type

						//是否必填货物重量(1:是,2:否)
						this.form.is_need_weight=bl_info.is_need_weight

						//是否必填货物体积(1:是,2:否)
						this.form.is_need_volume=bl_info.is_need_volume

						//接单自动发车(1:自动发车,2:不自动发车)
						this.form.is_auto_tord_start=bl_info.is_auto_tord_start

						//是否回程(1:需要,2:不需要)
						this.form.is_need_return=bl_info.is_need_return
						
						//是否购买保险(1:已购,2:未购)
						this.form.is_insure=bl_info.is_insure

						//货物价值
						this.form.goods_total=bl_info.goods_total

						//预付金额
						this.form.yufu_total=bl_info.yufu_total
						//运费
						this.form.freight_total=bl_info.freight_total
						
						//单价
						this.form.price= bl_info.price
						
						//单位
						this.form.price_unit=bl_info.price_unit

						//备注
						this.form.mark=bl_info.mark
									
						//结算方式变化
						this.settlement_type_change()
					}
				});
			},
			//项目列表
			get_company_item_list(){
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'app_user',
						ctr:'company_item_list',
					},callback:(res)=>{
						if(res.code != 0){
							this.company_item_list = []
							return
						}
						this.company_item_list = res.msg.list
						
					}
				})
			},
			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.cargo_type=creat_para.cargo_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.cargo_type=data.cargo_type
					}
				})
			},

			//置入用过的输入内用
			used_cargo_names_init(queryString,cb) {
				var createFilter=function(queryString){
					return (restaurant) => {
						return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
					};
				}
				var used_cargo_names = this.used_cargo_names;
				var results = queryString ? used_cargo_names.filter(createFilter(queryString)) : used_cargo_names;
				cb(results);
			},

			//使用过的货物名称初始化
			set_used_cargo_name(){
				let used_cargo_name=window.localStorage.getItem('cargo_creat_page_used_cargo_names');
				if(used_cargo_name){
					used_cargo_name=JSON.parse(used_cargo_name);
				}else used_cargo_name=[];
				this.used_cargo_names=[...used_cargo_name];
			},

			//货物名称输入
			cargo_name_change(){

				//查看此输入内容是否已收录
				let hasinputed=false;
				let used_cargo_names=[...this.used_cargo_names];
				for(var i in used_cargo_names){
					
					let item=used_cargo_names[i]

					//已收录,挪到第一位
					if(this.form.cargo_name==item.value){
						
						//删除原数组中对应的元素
						this.used_cargo_names.splice(i,1);

						//在原数组中新增此元素,并放到第一个未知
						this.used_cargo_names.unshift({
							value:this.form.cargo_name
						});
						
						//已收录
						hasinputed=true;
						break;
					}
				}

				//如果没有收录
				if(!hasinputed){

					//收录之
					this.used_cargo_names.unshift({
						value:this.form.cargo_name
					});
				}

				//保存到本地硬盘
				window.localStorage.setItem('cargo_creat_page_used_cargo_names',JSON.stringify(this.used_cargo_names))
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 110px);
		padding:0 15px;
		overflow-y: auto;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1100px;
		overflow: auto;
		margin: auto;
	}

</style>